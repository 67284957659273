import React from 'react'
import PropTypes from 'prop-types'
import './Container.scss'

const Container = ({ children, className }) => (
  <div className={`container ${className}`}>{children}</div>
)

export default Container

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}
