import React from 'react'
import PropTypes from 'prop-types'
import './IconButton.scss'
import { ReactComponent as FontBigger } from './icons/FontBigger.svg'
import { ReactComponent as NightMode } from './icons/NightMode.svg'
import { useTheme } from '../../ThemeProvider/ThemeProvider'

const ICONS = {
  FontBigger,
  NightMode
}

const IconButton = ({ btnType, onClick }) => {
  const { isDark } = useTheme()
  const className = isDark ? 'icon-button --dark' : 'icon-button'
  const SvgImage = ICONS[btnType]

  return (
    <button className={className} onClick={onClick}>
      <SvgImage color={isDark ? '#FFF' : '#044deb'} />
    </button>
  )
}

export default IconButton

IconButton.propTypes = {
  className: PropTypes.string,
  btnType: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func
}

IconButton.defaultProps = {
  btnType: 'FontBigger',
  onClick: () => {}
}
