import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'

import { createBrowserHistory } from 'history'
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'

import { createRootReducer, initialState } from './redux/reducers'
import { setData, getData } from './services/storage/localStorageService'
import sagas from './redux/sagas'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const { router, ...initialData } = getData()

const store = createStore(
  createRootReducer(history),
  Object.assign({}, initialState, { ...initialData }),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? compose(
      applyMiddleware(routerMiddleware(history), sagaMiddleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    )
    : compose(applyMiddleware(routerMiddleware(history), sagaMiddleware))
)

store.subscribe(() => {
  setData(store.getState())
})

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

sagaMiddleware.run(sagas)

serviceWorker.unregister()
