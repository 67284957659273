export const FETCH_JOBS = 'JOBS/FETCH_JOBS'
export const FETCH_JOBS_SUCCESS = 'JOBS/FETCH_JOBS_SUCCESS'
export const FETCH_MORE_JOBS_SUCCESS = 'JOBS/FETCH_MORE_JOBS_SUCCESS'
export const FETCH_JOBS_ERROR = 'JOBS/FETCH_JOBS_ERROR'

export function fetchJobsAction(payload) {
  return {
    type: FETCH_JOBS,
    payload
  }
}

export function fetchJobsActionError(payload) {
  return {
    type: FETCH_JOBS_ERROR,
    payload
  }
}

export function fetchJobsActionSuccess(payload) {
  return {
    type: FETCH_JOBS_SUCCESS,
    payload
  }
}
