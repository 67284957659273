import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { selectUserState, LOG_IN } from '../../../redux'
import config from '../../../app.config'
import { Helmet } from 'react-helmet'
import './login.scss'
import logo from '../../../images/logo.jpg'

function Login(props) {
  const userState = useSelector(selectUserState)
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()

  const sendForm = e => {
    e.preventDefault()
    e.stopPropagation()

    dispatch({
      type: LOG_IN,
      payload: {
        user,
        password
      }
    })
  }
  userState.loading = false;
  return (
    <div className="login-page">
      <Helmet>
        <title>{config.name} - Acceso usuarios</title>
        <meta name="description" content="Acceso usuarios área privada" />
      </Helmet>
      <div className="login">
        <img src={logo} className="App-logo" alt="logo" />
        <h6>Iniciar sesión en Inserta</h6>

        <form className="login-form" onSubmit={sendForm}>
          <div className="input-item">
            <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8 1.9a2.1 2.1 0 1 1 0 4.2 2.1 2.1 0 0 1 0-4.2zm0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H1.9V13c0-.64 3.13-2.1 6.1-2.1zM8 0C5.79 0 4 1.79 4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
                fill="#044DEB"
                fillRule="nonzero"
              />
            </svg>

            <input
              type="text"
              placeholder="Introduce tu email"
              name="user"
              id="user"
              onChange={e => setUser(e.target.value)}
            />
          </div>

          <div className="input-item">
            <svg width="16" height="21" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8 16c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V5c0-2.76-2.24-5-5-5S3 2.24 3 5h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H2C.9 7 0 7.9 0 9v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm0 12H2V9h12v10z"
                fill="#044DEB"
                fillRule="nonzero"
              />
            </svg>
            <input
              type="password"
              placeholder="Introduce tu contraseña"
              name="pass"
              id="pass"
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          {userState.error ? (
            <div className="error">Usuario o contraseña incorrectos</div>
          ) : null}
          {!userState.loading ? (
            <button type="submit">Iniciar sesión</button>
          ) : (
            <div className="loading">Cargando...</div>
          )}

          <Link to="/recordar-contrasena">¿Olvidaste tu contraseña?</Link>
        </form>
      </div>
    </div>
  )
}

export default Login
