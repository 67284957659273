import React from 'react'
import AnimatedNumber from 'animated-number-react'

const AnimatedTotal = ({ total = 0 }) => {
  const formatValue = value => Math.round(value)

  return <AnimatedNumber value={total} formatValue={formatValue} />
}

export default AnimatedTotal
