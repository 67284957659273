import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import userState, { initialState as userInitialState } from './user.reducer'
import jobsState, { initialState as jobsInitialState } from './jobs.reducer'
import jobsSummaryState, {
  initialState as jobsSummaryInitialState
} from './jobsSummary.reducer'
import filtersState, {
  initialState as filtersInitialState
} from './filters.reducer'
import territoriesState, {
  initialState as territoriesInitialState
} from './territories.reducer'

export const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    userState,
    jobsState,
    jobsSummaryState,
    filtersState,
    territoriesState
  })

export const initialState = {
  userState: userInitialState,
  jobsState: jobsInitialState,
  jobsSummaryState: jobsSummaryInitialState,
  filtersState: filtersInitialState,
  territoriesState: territoriesInitialState
}
