import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import config from '../../app.config'
import { Helmet } from 'react-helmet'
import CategoriesContainerContainer from '../../components/CategoriesContainer/CategoriesContainer.component'
import MapSelector from '../../components/MapSelector/MapSelector.component'
import AnimatedTotal from './AnimatedTotal'
import DataBarsContainer from '../../components/DataBarsContainer/DataBarsContainer.component'
import {
  fetchTerritoriesAction,
  selectJobsSummaryIsLoading,
  selectJobsSummaryError,
  selectJobsSummary,
  selectTerritories,
  selectAllTerritoriesOrderedAsList,
  selectFiltersState
} from '../../redux'
import { useFetchJobs } from '../../effects/useFetchJobs'
import './home.scss'
import { useSwitchToDemo } from '../../effects/useSwitchToDemo'

function getMapTitle(selectedTerritories, territories) {
  let mapTitle
  const selectedTerritoriesIds = Object.keys(selectedTerritories).filter(
    key => selectedTerritories[key]
  )

  if (selectedTerritoriesIds.length) {
    if (selectedTerritoriesIds.length === 1) {
      const selectedTerritory = selectedTerritoriesIds[0]
      mapTitle =
        territories[selectedTerritory - 1] &&
        territories[selectedTerritory - 1].label
    } else {
      mapTitle = `${selectedTerritoriesIds.length} territorios seleccionados`
    }
  }
  return mapTitle
}

function Home({ history }) {
  const dispatch = useDispatch()
  useSwitchToDemo(history)

  useEffect(() => {
    dispatch(fetchTerritoriesAction())
  }, [dispatch])

  const isJobsSummaryLoading = useSelector(selectJobsSummaryIsLoading)
  const jobsSummary = useSelector(selectJobsSummary)
  const error = useSelector(selectJobsSummaryError)

  const { totalFiltered } = jobsSummary
  useFetchJobs()

  const selectedTerritories = useSelector(selectTerritories)
  const territories = useSelector(selectAllTerritoriesOrderedAsList)
  const mapTitle = getMapTitle(selectedTerritories, territories)

  const filtersState = useSelector(selectFiltersState)

  return (
    <div className="home">
      <Helmet>
        <title>{config.name}</title>
        <meta name="description" content="Home" />
      </Helmet>
      <CategoriesContainerContainer filtersState={filtersState} />
      <div className="area-center">
        {mapTitle && <strong className="title">{mapTitle}</strong>}
        {!error && isJobsSummaryLoading && (
          <section className="subtitle">
            <span className="subtitle-partial">Cargando...</span>
          </section>
        )}
        {!error && !isJobsSummaryLoading && (
          <section className="subtitle">
            <span className="subtitle-partial">
              <AnimatedTotal total={totalFiltered} /> ofertas
            </span>
          </section>
        )}
        {error && !isJobsSummaryLoading && (
          <section className="subtitle">
            <span className="subtitle-partial">
              No se ha podido cargar la información.
            </span>
          </section>
        )}

        <MapSelector loading={isJobsSummaryLoading} />
      </div>
      <div className="area-right">
        <DataBarsContainer
          loading={isJobsSummaryLoading}
          jobsSummary={jobsSummary}
          filtersState={filtersState}
        />
      </div>
    </div>
  )
}

export default withRouter(Home)
