import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { selectTerritories, setTerritoryAction } from '../../redux'
import { TERRITORIES_SVG_DATA } from './territories.data'
import MapSelectorFooter from './MapSelectorFooter.component'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import { selectAllTerritories } from '../../redux/selectors/territories.selectors'
import './MapSelector.scss'

const MapSelector = () => {
  const dispatch = useDispatch()

  const selectedTerritories = useSelector(selectTerritories)
  const allTerritories = useSelector(selectAllTerritories)

  const selectItem = code => {
    dispatch(setTerritoryAction(code))
  }

  const [transformMatrix, setMatrix] = useState([1, 0, 0, 1, 0, 0])

  function zoom(scale) {
    const newMatrix = transformMatrix.slice(0)
    for (var i = 0; i < 4; i++) {
      newMatrix[i] *= scale
    }
    newMatrix[4] += (1 - scale) * 1
    newMatrix[5] += (1 - scale) * 1

    setMatrix(newMatrix)
  }

  function pan(dx, dy) {
    const newMatrix = transformMatrix.slice(0)

    newMatrix[4] += dx
    newMatrix[5] += dy

    setMatrix(newMatrix)
  }

  const { isDark } = useTheme()

  useEffect(() => {
    const territories = Object.keys(selectedTerritories)
    if (territories.length === 1) {
      const box = document.getElementById(territories[0]).getBBox()
      const newMatrix = [1.5, 0, 0, 1.5, 0, 0]
      newMatrix[4] += (949 / 2 - box.x) * 1.5
      newMatrix[5] += (745 / 2 - box.y) * 1.5
      setMatrix(newMatrix)
    } else if (territories.length === 0) {
      setMatrix([1, 0, 0, 1, 0, 0])
    }
  }, [selectedTerritories])

  if (!allTerritories || allTerritories.length === 0) {
    return null
  }

  return (
    <>
      <section className={isDark ? 'map-selector --dark' : 'map-selector'}>
        <div className="map-wr">
          <svg
            id="map-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 883 782"
            width="883"
            height="782"
            transform={`matrix(${transformMatrix.join(' ')})`}
          >
            <g fill="none" fillRule="evenodd">
              {TERRITORIES_SVG_DATA.map(t => {
                const code = allTerritories[t.id - 1].code
                const isSelected = selectedTerritories[code]
                return (
                  <React.Fragment key={t.id + Math.floor(Math.random() * 100)}>
                    <path
                      stroke={isDark ? '#051434' : '#FFF'}
                      strokeWidth="3"
                      d={t.svgStroke}
                    />
                    <path
                      fill={isDark ? '#172E61' : '#DBE6F7'}
                      className={isSelected ? 'selected' : ''}
                      onClick={() => selectItem(t.id)}
                      id={t.id}
                      d={t.svgFill}
                    />
                  </React.Fragment>
                )
              })}
            </g>
          </svg>
        </div>
      </section>

      <MapSelectorFooter
        {...{
          zoom,
          pan
        }}
      />
    </>
  )
}

export default MapSelector

MapSelector.propTypes = {
  className: PropTypes.string
}
