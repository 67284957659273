export const FETCH_TERRITORIES = 'USER/FETCH_TERRITORIES'
export const FETCH_TERRITORIES_SUCCESS = 'USER/FETCH_TERRITORIES_SUCCESS'
export const FETCH_TERRITORIES_ERROR = 'USER/FETCH_TERRITORIES_ERROR'

export function fetchTerritoriesAction(payload) {
  return {
    type: FETCH_TERRITORIES,
    payload
  }
}

export function fetchTerritoriesActionError(payload) {
  return {
    type: FETCH_TERRITORIES_ERROR,
    payload
  }
}

export function fetchTerritoriesActionSuccess(payload) {
  return {
    type: FETCH_TERRITORIES_SUCCESS,
    payload
  }
}
