import config from '../config'
import axios from 'axios'

let user = null

export const logIn = ({ email, password }) => {
  return axios
    .post(`${config.authApi}/login`, {
      email,
      password
    })
    .then(result => {
      console.log('Login successful')
      user = result
      return result
    })
    .catch(err => {
      console.error('Error logging in')
      user = null
      throw err
    })
}

export const changePassword = ({ user, oldPassword, newPassword }) => {
  return axios
    .post(`${config.authApi}/change-password`, {
      newPassword,
      password: oldPassword,
      email: user
    })
    .then(result => {
      return result
    })
    .catch(err => {
      throw err
    })
}

export const logOut = () => {
  user = null
  return Promise.resolve()
}

export const isLogged = () => {
  return !!user
}

export const getUserToken = () => {
  return isLogged() ? user.token : null
}

// export const register = (data) => {
//   user = data
//   return Promise.resolve(user)
// }

// export const sendResetPasswordToken = data => {
//   return Promise.resolve()
// }

// export const resetPassword = data => {
//   return Promise.resolve()
// }
