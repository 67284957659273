import {
  FETCH_JOBS,
  FETCH_JOBS_ERROR,
  FETCH_JOBS_SUCCESS,
  FETCH_MORE_JOBS_SUCCESS
} from '../actions/jobs.actions'

export const initialState = {
  jobs: [],
  error: false,
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBS:
      return {
        ...initialState,
        loading: true,
        error: false
      }
    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        jobs: action.payload
      }
    case FETCH_MORE_JOBS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        jobs: [...state.jobs, ...action.payload]
      }
    case FETCH_JOBS_ERROR:
      return {
        ...state,
        jobs: [],
        error: true,
        loading: false
      }

    default:
      return state
  }
}
