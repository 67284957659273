import { all } from 'redux-saga/effects'

import { watchLoginSaga } from './user.sagas'
import { watchFetchJobsSaga } from './jobs.sagas'
import { watchFetchJobsSummarySaga } from './jobsSummary.sagas'
import { watchFilterChangeSaga } from './filters.sagas'
import { watchFetchTerritoriesSaga } from './territories.sagas'

export default function* root() {
  yield all([
    watchLoginSaga(),
    watchFetchJobsSaga(),
    watchFetchJobsSummarySaga(),
    watchFilterChangeSaga(),
    watchFetchTerritoriesSaga()
  ])
}
