import { useEffect } from 'react'

const KEYS = {
  D: 68,
  ESCAPE: 27
}

export const useSwitchToDemo = history => {
  useEffect(() => {
    const handleKeyDown = event => {
      switch (event.keyCode) {
        case KEYS.ESCAPE:
          history.push('/')
          break
        case KEYS.D:
          history.push('/demo')
          break
        default:
          break
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [history])
}
