import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Home from './Home'
import { setTerritoryAction, clearFilters } from '../../redux'

const TERRITORIES = ['17', '14', '03', '04', '13', '16', '15', '06', '09']

const getRandomTerritory = () => {
  const rand = Math.floor(Math.random() * TERRITORIES.length)
  return TERRITORIES[rand]
}

export default function HomeDemo() {
  const dispatch = useDispatch()
  useEffect(() => {
    setTimeout(function() {
      dispatch(clearFilters())
      dispatch(setTerritoryAction(getRandomTerritory()))
    }, 500)
    const timer = setInterval(() => {
      dispatch(clearFilters())
      setTimeout(function() {
        dispatch(setTerritoryAction(getRandomTerritory()))
      }, 1300)
    }, 1000 * 20)
    return () => clearInterval(timer)
  }, [dispatch])

  return <Home />
}
