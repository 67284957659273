import {
  SET_COMPANY,
  SET_TERRITORY,
  SET_TECHNOLOGY,
  CLEAR_FILTERS,
  SET_ORDER,
  SET_PAGE,
  SET_FILTERS,
  SET_JOB_DETAIL,
  SET_DATE_RANGE,
  RESET_COMPANIES,
  RESET_TECHNOLOGIES,
  RESET_TERRITORIES,
  SET_SHOW_ACTIVE,
  SET_SHOW_ALL_OFFERS,
  SET_LIST_MODE
} from '../actions/filters.actions'

export const VIEW_TYPE = {
  GRID: 'grid-view',
  LIST: 'list-view'
}

export const initialState = {
  range: 'last-year',
  companies: {},
  technologies: {},
  territories: {},
  order: null,
  page: 1,
  detail: null,
  showActive: true,
  showAllOffers: false,
  listViewMode: VIEW_TYPE.GRID
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTERS:
      const {
        range,
        companies,
        technologies,
        territories,
        order,
        page,
        detail,
        showActive,
        showAllOffers,
        listViewMode
      } = action.payload
      return {
        range: range || 'last-year',
        companies: companies || {},
        technologies: technologies || {},
        territories: territories || {},
        order: order || null,
        page: page ? parseInt(page, 10) : 1,
        detail,
        listViewMode,
        showActive:
          typeof showActive !== 'undefined' ? showActive : state.showActive,
        showAllOffers:
          typeof showAllOffers !== 'undefined' ? showAllOffers : state.showAllOffers
      }

    case SET_JOB_DETAIL:
      return {
        ...state,
        detail: action.payload
      }

    case SET_COMPANY:
      const newCompanies = {}
      newCompanies[action.payload] = !state.companies[action.payload]
      return {
        ...state,
        page: initialState.page,
        companies: {
          ...state.companies,
          ...newCompanies
        }
      }

    case SET_TECHNOLOGY:
      const newTechnologies = {}
      newTechnologies[action.payload] = !state.technologies[action.payload]
      return {
        ...state,
        page: initialState.page,
        technologies: {
          ...state.technologies,
          ...newTechnologies
        }
      }

    case SET_DATE_RANGE:
      return {
        ...state,
        page: initialState.page,
        range: action.payload
      }

    case SET_TERRITORY:
      const newTerritories = {}
      newTerritories[action.payload] = !state.territories[action.payload]
      return {
        ...state,
        page: initialState.page,
        territories: {
          ...state.territories,
          ...newTerritories
        }
      }

    case RESET_TERRITORIES:
      return {
        ...state,
        page: initialState.page,
        territories: {
          ...initialState.territories
        }
      }

    case RESET_TECHNOLOGIES:
      return {
        ...state,
        page: initialState.page,
        technologies: {
          ...initialState.technologies
        }
      }

    case RESET_COMPANIES:
      return {
        ...state,
        page: initialState.page,
        companies: {
          ...initialState.companies
        }
      }

    case SET_SHOW_ACTIVE:
      return {
        ...state,
        page: initialState.page,
        showActive: action.payload
      }

    case SET_SHOW_ALL_OFFERS:
      return {
        ...state,
        page: initialState.page,
        showAllOffers: action.payload
      }

    case CLEAR_FILTERS: {
      return {
        ...initialState
      }
    }

    case SET_ORDER:
      return {
        ...state,
        order: action.payload
      }

    case SET_PAGE:
      return {
        ...state,
        page: action.payload
      }

    case SET_LIST_MODE:
      return { ...state, listViewMode: action.payload }

    default:
      return state
  }
}
