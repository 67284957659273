import axios from 'axios'
import queryString from 'query-string'
import config from '../config'
import { isEmpty } from '../../util'

const buildGetJobsParams = (
  range,
  companies,
  technologies,
  territories,
  lastId,
  orderBy,
  page,
  showActive,
  showAllOffers
) => {
  const params = { range }
  const companyParam = getSelectedValuesString(companies)
  if (companyParam) {
    params.company = companyParam
  }
  const technologiesParam = getSelectedValuesString(technologies)
  if (technologiesParam) {
    params.technologies = technologiesParam
  }
  const territoriesParam = getSelectedValuesString(territories)
  if (territoriesParam) {
    params.territories = territoriesParam
  }
  if (lastId) {
    params.lastId = lastId
  }
  if (orderBy) {
    params.sortBy = orderBy
  }
  if (page) {
    params.page = page - 1
  }
  if (showActive) {
    params.showActive = showActive
  }

  if (showAllOffers) {
    params.allJobs = showAllOffers
  }
  
  return queryString.stringify(params)
}

const getSelectedValuesString = data => {
  if (isEmpty(data)) {
    return null
  }
  const selectedItems = []
  Object.keys(data).forEach(item => {
    if (!!data[item]) {
      selectedItems.push(item)
    }
  })
  return selectedItems.length ? selectedItems.join(',') : null
}

export const getJobsSummary = (
  range,
  companies,
  technologies,
  territories,
  showActive,
  showAllOffers,
  token
) => {
  const params = { range }

  const companyParam = getSelectedValuesString(companies)
  if (companyParam) {
    params.company = companyParam
  }
  const technologiesParam = getSelectedValuesString(technologies)
  if (technologiesParam) {
    params.technologies = technologiesParam
  }
  const territoriesParam = getSelectedValuesString(territories)
  if (territoriesParam) {
    params.territories = territoriesParam
  }

  if (showActive) {
    params.showActive = showActive
  }

  if (showAllOffers) {
    params.allJobs = showAllOffers
  }

  const stringified = queryString.stringify(params)
  const url = `${config.jobsApi}/jobs/aggregations?${stringified}`
  return axios
    .get(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then(response => response.data)
}

export const getJobs = (
  range,
  companies,
  technologies,
  territories,
  lastId,
  orderBy,
  page,
  showActive,
  showAllOffers,
  token
) => {
  const stringifiedParams = buildGetJobsParams(
    range,
    companies,
    technologies,
    territories,
    lastId,
    orderBy,
    page,
    showActive,
    showAllOffers
  )
  const url = `${config.jobsApi}/jobs?${stringifiedParams}`
  return axios
    .get(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then(response => response.data)
}

export const getJob = (slug, token) => {
  const url = `${config.jobsApi}/job/${slug}`
  return axios
    .get(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then(response => response.data)
}

export const getJobsCsv = (
  range,
  companies,
  technologies,
  territories,
  lastId,
  orderBy,
  page,
  showActive,
  showAllOffers,
  token
) => {
  const stringifiedParams = buildGetJobsParams(
    range,
    companies,
    technologies,
    territories,
    lastId,
    orderBy,
    page,
    showActive,
    showAllOffers
  )
  const url = `${config.jobsApi}/jobs-csv?${stringifiedParams}`
  return axios
    .get(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'export.csv')
      document.body.appendChild(link)
      link.click()
    })
}
