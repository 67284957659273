import { put, takeLatest, select } from 'redux-saga/effects'
import { buildUrlParamsFromState } from '../../util'
import { push } from 'connected-react-router'

import {
  fetchJobsSummaryAction,
  fetchJobsAction,
  SET_TECHNOLOGY,
  SET_COMPANY,
  SET_TERRITORY,
  SET_ORDER,
  SET_PAGE,
  SET_FILTERS,
  SET_JOB_DETAIL,
  RESET_COMPANIES,
  RESET_TECHNOLOGIES,
  RESET_TERRITORIES,
  SET_DATE_RANGE,
  SET_SHOW_ACTIVE,
  SET_SHOW_ALL_OFFERS,
  SET_LIST_MODE
} from '../actions'

import { selectFiltersState } from '../selectors'

function* dispatchFetchJobsData() {
  yield put(fetchJobsSummaryAction())
  yield put(fetchJobsAction())
}

function* updateUrlParameters() {
  const filters = yield select(selectFiltersState)
  const params = buildUrlParamsFromState(filters)
  yield put(push(`${window.location.pathname}?${params}`))
}

export function* watchFilterChangeSaga() {
  yield takeLatest(SET_TECHNOLOGY, dispatchFetchJobsData)
  yield takeLatest(SET_TERRITORY, dispatchFetchJobsData)
  yield takeLatest(SET_COMPANY, dispatchFetchJobsData)
  yield takeLatest(SET_ORDER, dispatchFetchJobsData)
  yield takeLatest(SET_PAGE, dispatchFetchJobsData)
  yield takeLatest(SET_FILTERS, dispatchFetchJobsData)
  yield takeLatest(SET_SHOW_ACTIVE, dispatchFetchJobsData)
  yield takeLatest(SET_SHOW_ALL_OFFERS, dispatchFetchJobsData)
  yield takeLatest(SET_DATE_RANGE, dispatchFetchJobsData)

  yield takeLatest(SET_TECHNOLOGY, updateUrlParameters)
  yield takeLatest(SET_TERRITORY, updateUrlParameters)
  yield takeLatest(SET_COMPANY, updateUrlParameters)
  yield takeLatest(SET_ORDER, updateUrlParameters)
  yield takeLatest(SET_PAGE, updateUrlParameters)
  yield takeLatest(SET_JOB_DETAIL, updateUrlParameters)
  yield takeLatest(RESET_COMPANIES, updateUrlParameters)
  yield takeLatest(RESET_TECHNOLOGIES, updateUrlParameters)
  yield takeLatest(RESET_TERRITORIES, updateUrlParameters)
  yield takeLatest(SET_SHOW_ACTIVE, updateUrlParameters)
  yield takeLatest(SET_SHOW_ALL_OFFERS, updateUrlParameters)
  yield takeLatest(SET_DATE_RANGE, updateUrlParameters)
  yield takeLatest(SET_PAGE, updateUrlParameters)
  yield takeLatest(SET_LIST_MODE, updateUrlParameters)
}
