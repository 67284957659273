const pack = require('../../../package.json')

export const setData = data => {
  try {
    localStorage.setItem('mj-' + pack.version, JSON.stringify(data))
  } catch (err) {
    console.error(err)
  }
}

export const getData = () => {
  try {
    return JSON.parse(localStorage.getItem('mj-' + pack.version)) || {}
  } catch (e) {
    console.error(e)
    return {}
  }
}
