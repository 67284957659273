import { createSelector } from 'reselect'

export const selectFiltersState = state => state.filtersState

export const selectTechnologies = createSelector(
  selectFiltersState,
  state => state.technologies
)

export const selectDateRange = createSelector(
  selectFiltersState,
  state => state.range
)

export const selectTerritories = createSelector(
  selectFiltersState,
  state => state.territories
)

export const selectCompanies = createSelector(
  selectFiltersState,
  state => state.companies
)

export const selectOrder = createSelector(
  selectFiltersState,
  state => state.order
)

export const selectPage = createSelector(
  selectFiltersState,
  state => state.page
)

export const selectShowActive = createSelector(
  selectFiltersState,
  state => state.showActive
)

export const selectShowAllOffers = createSelector(
  selectFiltersState,
  state => state.showAllOffers
)

export const selectViewType = createSelector(
  selectFiltersState,
  state => state.listViewMode
)
