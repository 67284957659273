import React, { useState, useContext } from 'react'

const ThemeContext = React.createContext({})

const useTheme = () => {
  return useContext(ThemeContext)
}

const ThemeProvider = ({ children }) => {
  const [themeState, setThemeState] = useState({
    isDark: false,
    isBiggerFont: false
  })

  const toggleTheme = () => {
    const isDark = !themeState.isDark
    const bodyEl = document.querySelector('body')
    isDark
      ? bodyEl.classList.add('dark-mode')
      : bodyEl.classList.remove('dark-mode')
    setThemeState({ ...themeState, isDark })
  }

  const toggleFontSize = () => {
    const isBiggerFont = !themeState.isBiggerFont
    const bodyEl = document.querySelector('body')
    isBiggerFont
      ? bodyEl.classList.add('bigger-font')
      : bodyEl.classList.remove('bigger-font')
    setThemeState({ ...themeState, isBiggerFont })
  }

  return (
    <ThemeContext.Provider
      value={{
        isDark: themeState.isDark,
        toggleTheme,
        toggleFontSize
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, useTheme }
