import { createSelector } from 'reselect'
import { sortObjectAsList } from '../../util'

export const selectJobsSummaryState = state => state.jobsSummaryState

export const selectJobsSummaryIsLoading = createSelector(
  selectJobsSummaryState,
  state => state.loading
)

export const selectJobsSummaryError = createSelector(
  selectJobsSummaryState,
  state => state.error
)

export const selectJobsSummary = createSelector(
  selectJobsSummaryState,
  data => data.data || {}
)

export const selectJobsSummaryCompaniesSortedAsList = createSelector(
  selectJobsSummary,
  data => {
    return sortObjectAsList(data.companies || {})
  }
)

export const selectAllCompaniesSortedAsList = createSelector(
  selectJobsSummary,
  data => {
    return sortObjectAsList(data.allCompanies || {})
  }
)

export const selectTopCompanies = createSelector(
  selectJobsSummaryCompaniesSortedAsList,
  companies => companies.slice(0, 5)
)

export const selectJobsSummaryTechnologiesSortedAsList = createSelector(
  selectJobsSummary,
  data => {
    return sortObjectAsList(data.technologies || {})
  }
)

export const selectAllTechnologiesSortedAsList = createSelector(
  selectJobsSummary,
  data => {
    return sortObjectAsList(data.allTechnologies || {})
  }
)

export const selectTopTechnologies = createSelector(
  selectJobsSummaryTechnologiesSortedAsList,
  technologies => technologies.slice(0, 5)
)

export const selectTotalJobs = createSelector(
  selectJobsSummary,
  data => data.totalFiltered
)
