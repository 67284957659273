import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import Header from './components/Header/Header.component'
import { ThemeProvider } from './components/ThemeProvider/ThemeProvider'
import SubHeader from './components/SubHeader/SubHeader.component'
import Footer from './components/Footer/Footer.component'
import Container from './components/Layout/Container/Container.component'
import Routes from './routes'

import { selectIsLoggedIn } from './redux'

import { spanish } from './translations'
import { useSetFiltersOnLoad } from './effects/useSetFiltersOnLoad'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: spanish
    },
    lng: 'es',
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false
    }
  })

function App({ history, location }) {
  const isLoggedIn = useSelector(selectIsLoggedIn)

  const dispatch = useDispatch()
  useSetFiltersOnLoad(location, dispatch)

  return (
    <ThemeProvider>
      <div className="App">
        <Header />
        {isLoggedIn ? <SubHeader history={history} /> : null}
        <div className="app-body">
          <Container>
            <Switch>
              {Routes.map(r => (
                <Route
                  path={r.path}
                  key={r.path || 'not-found'}
                  exact={r.exact}
                  render={routeProps =>
                    !isLoggedIn && r.private ? (
                      <Redirect to="/login" />
                    ) : (
                      <r.component {...routeProps} metadata={r.metadata} />
                    )
                  }
                />
              ))}
            </Switch>
          </Container>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default withRouter(App)
