import React from 'react'
import PropTypes from 'prop-types'
import JobModal from '../JobModal/JobModal'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectJobsState,
  setJobDetailAction,
  selectFiltersState
} from '../../redux'

import './CardsContainer.scss'
import CardItem from '../CardItem/CardItem'

const LoadingCards = () => (
  <div className="cards-container">
    <CardItem loading={true} />
    <CardItem loading={true} />
    <CardItem loading={true} />
    <CardItem loading={true} />
  </div>
)

const CardsContainer = ({ className, viewType }) => {
  const jobsState = useSelector(selectJobsState)
  const filtersState = useSelector(selectFiltersState)
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(setJobDetailAction())
  }

  const openModalForJob = job => {
    dispatch(setJobDetailAction(job.id))
  }

  if (jobsState.error) {
    return (
      <section className="error-container">
        Error obteniendo los datos :(
      </section>
    )
  }
  if (jobsState.loading) {
    return <section className="error-container">Cargando datos...</section>
  }

  if (jobsState.jobs.length === 0) {
    return (
      <section className="error-container">
        No hay datos para la fecha seleccionada
      </section>
    )
  }
  return (
    <section className={`cards-container ${className}`}>
      {jobsState.loading ? (
        <LoadingCards />
      ) : (
        <div className="cards-container">
          {jobsState.jobs.map(j => (
            <CardItem
              job={j}
              key={j.id}
              className={viewType}
              onClickJob={openModalForJob}
            />
          ))}
          <JobModal
            job={jobsState.jobs.find(i => i.id === filtersState.detail)}
            opened={!!filtersState.detail}
            onClickClose={closeModal}
          />
        </div>
      )}
    </section>
  )
}

export default CardsContainer

CardsContainer.propTypes = {
  className: PropTypes.string
}
