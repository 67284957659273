import React from 'react'
import './modal.scss'

export default function Modal(props) {
  const { opened, onClickClose, isDark, children } = props
  if (!opened) return null
  return (
    <div
      className={`modal ${opened ? 'opened' : ''}  ${isDark ? '--dark' : ''}`}
    >
      <div className="overlay" onClick={onClickClose}></div>
      <div className="actions">
        <div className="close" onClick={onClickClose}></div>
      </div>
      <div className="content-modal">{children}</div>
    </div>
  )
}
