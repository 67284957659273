import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import CategoryCheck from './CategoryCheck/CategoryCheck.component'

import {
  selectCompanies,
  selectTechnologies,
  selectTerritories,
  selectJobsSummaryCompaniesSortedAsList,
  selectJobsSummaryTechnologiesSortedAsList,
  selectAllTerritoriesOrderedAsList,
  setCompanyAction,
  setTechnologyAction,
  setTerritoryAction,
  resetCompaniesAction,
  resetTechnologiesAction,
  resetTerritoriesAction,
  setShowActiveOffersAction,
  selectShowActive,
  setShowAllOffersAction,
  selectShowAllOffers
} from '../../redux'

import './CategoriesContainer.scss'
import { CATEGORIES } from './constants'

const CategoriesContainer = () => {
  const dispatch = useDispatch()
  const selectedCompanies = useSelector(selectCompanies)
  const selectedTechnologies = useSelector(selectTechnologies)
  const selectedTerritories = useSelector(selectTerritories)

  const companies = useSelector(selectJobsSummaryCompaniesSortedAsList)
  const technologies = useSelector(selectJobsSummaryTechnologiesSortedAsList)
  const territories = useSelector(selectAllTerritoriesOrderedAsList)

  const showActive = useSelector(selectShowActive)
  const showAllOffers = useSelector(selectShowAllOffers)

  const onChangeCompanyCategory = company => {
    dispatch(setCompanyAction(company))
  }

  const onChangeTechonologyCategory = technology => {
    dispatch(setTechnologyAction(technology))
  }

  const onChangeTerritoryCategory = territory => {
    dispatch(setTerritoryAction(territory))
  }

  const resetFilter = category => {
    switch (category) {
      case CATEGORIES.TECHNOLOGIES:
        dispatch(resetTechnologiesAction())
        break;
      case CATEGORIES.CATEGORIES:
        dispatch(resetTechnologiesAction())
        break;
      case CATEGORIES.COMPANIES:
        dispatch(resetCompaniesAction())
        break;
      case CATEGORIES.TERRITORIES:
        dispatch(resetTerritoriesAction())
        break;
      default:
        console.log('none is selected');
        break;
    }
  }

  return (
    <section className="categories-container">
      <CategoryCheck
        categoryTitle={showAllOffers ? CATEGORIES.CATEGORIES : CATEGORIES.TECHNOLOGIES}
        selectedItems={selectedTechnologies}
        dataItems={technologies}
        onChangeCategory={onChangeTechonologyCategory}
        showCoincidencesNumber={true}
        resetFilter={resetFilter}
        placeHolder={'EJ: Obra, Informática, Camarero..'}
        showSearch={true}
      />
      < CategoryCheck
        categoryTitle={CATEGORIES.COMPANIES}
        selectedItems={selectedCompanies}
        dataItems={companies}
        onChangeCategory={onChangeCompanyCategory}
        showCoincidencesNumber={true}
        resetFilter={resetFilter}
        placeHolder={'EJ: Iberia, Ibermática, Arelance..'}
        showSearch={true}
      />

      <CategoryCheck
        categoryTitle={CATEGORIES.TERRITORIES}
        selectedItems={selectedTerritories}
        dataItems={territories}
        onChangeCategory={onChangeTerritoryCategory}
        resetFilter={resetFilter}
        showSearch={false}
      />

      <div className="show-active">
        <input
          id="show-active"
          type="checkbox"
          onChange={() => {
            dispatch(setShowActiveOffersAction(!showActive))
          }}
          checked={!!showActive}
        ></input>
        <label htmlFor="show-active">Mostrar sólo ofertas activas</label>
      </div>

      <div className="show-all-offers">
        <input
          id="show-all-offers"
          type="checkbox"
          onChange={() => {
            dispatch(setShowAllOffersAction(!showAllOffers))
          }}
          checked={showAllOffers}
        ></input>
        <label htmlFor="show-all">Mostrar todas las ofertas</label>
      </div>
    </section >
  )
}

export default withRouter(CategoriesContainer)

CategoriesContainer.propTypes = {
  companies: PropTypes.array,
  technologies: PropTypes.array,
  territories: PropTypes.array,
  onChangeCompanyCategory: PropTypes.func,
  onChangeTechonologyCategory: PropTypes.func,
  onChangeTerritoryCategory: PropTypes.func
}
