import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchJobsSummaryAction, fetchJobsAction } from '../redux'

export const useFetchJobs = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchJobsSummaryAction())
    dispatch(fetchJobsAction())
  }, [dispatch])
}
