import axios from 'axios'
import config from '../config'

export const getTerritories = token => {
  const url = `${config.jobsApi}/territories`
  return axios
    .get(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then(response => {
      return response.data
    })
}
