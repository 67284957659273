import React from 'react'
import PropTypes from 'prop-types'
import './CategoryCheckItem.scss'

const CategoryCheckItem = ({
  className,
  categoryCheckTitle,
  categoryValue,
  countResults,
  onChangeCategory,
  checked
}) => {
  const onChange = () => {
    onChangeCategory(categoryValue)
    return true
  }
  return (
    <li className={`category-check-item ${className}`}>
      <input
        id={categoryCheckTitle}
        type="checkbox"
        onChange={onChange}
        checked={checked}
      ></input>
      <label htmlFor={categoryCheckTitle}>
        {categoryCheckTitle}
        {countResults ? ` (${countResults})` : ''}
      </label>
    </li>
  )
}

export default CategoryCheckItem

CategoryCheckItem.propTypes = {
  className: PropTypes.string,
  categoryCheckTitle: PropTypes.string,
  categoryValue: PropTypes.string,
  onChangeCategory: PropTypes.func
}
