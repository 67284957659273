import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '../ThemeProvider/ThemeProvider'
import CompanyLogo from '../CompanyLogo/CompanyLogo'

import './CardItem.scss'
import { formatDate } from '../../util'

const formatTechnology = technology => {
  const formattedTechnology = technology.split(' ').reduce((acc, current) => {
    const toCamelCase = `${current.substr(0, 1).toUpperCase()}${current.substr(
      1
    )}`
    if (acc.length === 0) {
      return toCamelCase
    }
    return `${acc} ${current.substr(0, 1).toUpperCase()}${current.substr(1)}`
  }, '')
  return formattedTechnology
}

const CardItem = ({ className, job, loading, isModalOpen, onClickJob }) => {
  const { isDark } = useTheme()

  if (loading) {
    return (
      <aside
        className={`card-item ${className} loading  ${isDark ? '--dark' : ''}`}
      >
        <div className="content">
          <div className="logo-company"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </aside>
    )
  }

  const {
    company,
    company_logo,
    title,
    published,
    ubication,
    technologies,
    active
  } = job
  const formattedDate = formatDate(published)

  const technology = formatTechnology(technologies[0])
  return (
    <aside
      className={`card-item ${className} ${isDark && '--dark'} ${!active &&
        '--closed'}`}
    >
      <div className="content" onClick={() => onClickJob(job)}>
        {!active && <div className="closed">Cerrada</div>}
        <CompanyLogo company={company} logo={company_logo} />
        <h5>{technology}</h5>
        <small>{company}</small>
        <h6>{title}</h6>

        <footer>
          <span>{formattedDate}</span>
          <span>{ubication}</span>
        </footer>
      </div>
    </aside>
  )
}

export default CardItem

CardItem.propTypes = {
  className: PropTypes.string,
  job: PropTypes.object
}
