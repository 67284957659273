import {
  FETCH_TERRITORIES,
  FETCH_TERRITORIES_SUCCESS,
  FETCH_TERRITORIES_ERROR
} from '../actions/territories.actions'

export const initialState = {
  territories: null,
  error: false,
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TERRITORIES:
      return {
        ...initialState,
        loading: true,
        error: false
      }

    case FETCH_TERRITORIES_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        territories: action.payload
      }

    case FETCH_TERRITORIES_ERROR:
      return {
        ...state,
        territories: null,
        error: true,
        loading: false
      }
    default:
      return state
  }
}
