import React from 'react'
import './CompanyLogo.scss'

const CompanyLogo = ({ company, logo, whiteBackground }) => {
  if (logo) {
    return (
      <div className="company-logo">
        <img src={logo} alt={company} style={whiteBackground ? { backgroundColor: 'white', padding: '0.5rem' } : {}} />
      </div>
    )
  }
  let initials = '?'
  if (company) {
    initials = company.split(' ').reduce((prev, curr) => {
      return (prev += curr.substr(0, 1))
    }, '')
  }
  return <div className="company-initials">{initials}</div>
}

export default CompanyLogo
