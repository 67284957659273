import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getJobsSummary } from '../../services/jobs/jobsService'
import { push } from 'connected-react-router'

import {
  FETCH_JOBS_SUMMARY,
  fetchJobsSummaryActionSuccess,
  fetchJobsSummaryActionError,
  logoutAction
} from '../actions'
import {
  selectDateRange,
  selectCompanies,
  selectTechnologies,
  selectTerritories,
  selectShowActive,
  selectToken,
  selectShowAllOffers
} from '../selectors'

function* fetchJobsSummarySaga() {
  const range = yield select(selectDateRange)
  const companies = yield select(selectCompanies)
  const technologies = yield select(selectTechnologies)
  const territories = yield select(selectTerritories)
  const showActive = yield select(selectShowActive)
  const showAllOffers = yield select(selectShowAllOffers)
  const token = yield select(selectToken)

  try {
    const result = yield call(
      getJobsSummary,
      range,
      companies,
      technologies,
      territories,
      showActive,
      showAllOffers,
      token
    )

    yield put(fetchJobsSummaryActionSuccess(result))
  } catch (e) {
    yield put(fetchJobsSummaryActionError(e))
    if (e.response && e.response.status === 401) {
      yield put(logoutAction())
      yield put(push('/'))
    }
  }
}

export function* watchFetchJobsSummarySaga() {
  yield takeLatest(FETCH_JOBS_SUMMARY, fetchJobsSummarySaga)
}
