import {
  LOG_OUT,
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  REMIND_PASSWORD,
  REMIND_PASSWORD_ERROR,
  REMIND_PASSWORD_SUCCESS
} from '../actions/user.actions'

export const initialState = {
  user: null,
  token: null,
  error: false,
  loading: false,
  errorRemindPassword: false,
  loadingRemindPassword: false,
  successRemindPassword: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOG_OUT:
      return {
        ...initialState
      }

    case LOG_IN:
      return {
        ...initialState,
        loading: true,
        error: false,
        successRemindPassword: false
      }

    case LOG_IN_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        user: action.payload.user,
        token: action.payload.token
      }

    case LOG_IN_ERROR:
      return {
        ...state,
        user: null,
        error: true,
        loading: false
      }

    case REMIND_PASSWORD:
      return {
        ...state,
        loadingRemindPassword: true,
        errorRemindPassword: false,
        successRemindPassword: false
      }

    case REMIND_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingRemindPassword: false,
        errorRemindPassword: false,
        successRemindPassword: true
      }

    case REMIND_PASSWORD_ERROR:
      return {
        ...state,
        loadingRemindPassword: false,
        errorRemindPassword: true,
        successRemindPassword: false
      }

    default:
      return state
  }
}
