import {
  FETCH_JOBS_SUMMARY,
  FETCH_JOBS_SUMMARY_ERROR,
  FETCH_JOBS_SUMMARY_SUCCESS
} from '../actions/jobsSummary.actions'

export const initialState = {
  data: {},
  error: false,
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBS_SUMMARY:
      return {
        ...state,
        loading: true,
        error: false
      }

    case FETCH_JOBS_SUMMARY_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        data: action.payload
      }

    case FETCH_JOBS_SUMMARY_ERROR:
      return {
        ...state,
        data: initialState.data,
        error: true,
        loading: false
      }

    default:
      return state
  }
}
