import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setPage, selectPage } from '../../redux'

import './pagination.scss'

const TOTAL_VISIBLE = 3
const Pagination = ({ totalFiltered }) => {
  const page = useSelector(selectPage)
  const dispatch = useDispatch()
  const totalPages = Math.round(totalFiltered / 16)

  const onClickPage = page => {
    dispatch(setPage(page))
  }

  const pages = []
  let count = 0
  for (let i = page || 0; i < totalPages && count < TOTAL_VISIBLE; i++) {
    pages.push(
      <li key={i} className="pagination-button" onClick={() => onClickPage(i)}>
        {i}
      </li>
    )
    count++
  }

  const isInLastPages = totalPages / 2 < page

  return (
    <ul className="pagination">
      {page === 1 ? (
        <li>← Anterior</li>
      ) : (
          <li className="pagination-button" onClick={() => onClickPage(page - 1)}>
            ← Anterior
          </li>
        )}
      {isInLastPages ? (
        <li className="pagination-button" onClick={() => onClickPage(1)}>
          1
        </li>
      ) : null}

      {isInLastPages ? <li>...</li> : null}

      {pages}

      {isInLastPages ? null : <li>...</li>}

      <li className="pagination-button" onClick={() => onClickPage(totalPages)}>
        {totalPages}
      </li>

      {page === totalPages ? (
        <li>Siguiente →</li>
      ) : (
          <li className="pagination-button" onClick={() => onClickPage(page + 1)}>
            Siguiente →
          </li>
        )}
    </ul>
  )
}

export default Pagination
