export function pickTop(number, map) {
  return Object.entries(map)
    .sort((a, b) => b[1] - a[1])
    .slice(0, number)
}

export function orderObjectByValue(map) {
  return Object.entries(map).sort((a, b) => b[1] - a[1])
}

export function isEmpty(obj) {
  if (!obj) return true
  return Object.entries(obj).length === 0
}

function compareString(a, b) {
  var nameA = a.key.toLowerCase(), nameB = b.key.toLowerCase();
  return nameA.localeCompare(nameB);
}

export const sortObjectAsList = obj => {
  let tmp = Object.keys(obj)
    .map(i => ({
      label: i,
      key: i,
      value: obj[i]
    }))
    .sort(compareString)

  return tmp;

}

export const formatDate = date => {
  return new Date(date).toLocaleDateString()
}

export const buildUrlParamsFromState = mergedState => {
  let params = ''

  Object.keys(mergedState).forEach(filter => {
    if (mergedState[filter]) {
      // Filter repesented by an object (technolgies, territories, companies)
      if (mergedState[filter] === Object(mergedState[filter])) {
        const companyKeys = Object.keys(mergedState[filter]).filter(
          key => mergedState[filter][key]
        )
        if (companyKeys.length) {
          params += `${filter}=${companyKeys.join(',')}&`
        }
        // Filter repesented by a value (date-range, page)
      } else {
        params += `${filter}=${mergedState[filter]}&`
      }
    } else {
      if (
        typeof mergedState[filter] !== 'undefined' &&
        mergedState[filter] !== null
      ) {
        params += `${filter}=${mergedState[filter]}&`
      }
    }
  })

  return params
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    console.error('Old browser')
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!')
    },
    function (err) {
      console.error('Async: Could not copy text: ', err)
    }
  )
}
