import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getJobs } from '../../services/jobs/jobsService'

import {
  FETCH_JOBS,
  fetchJobsActionSuccess,
  fetchJobsActionError
} from '../actions'
import {
  selectDateRange,
  selectCompanies,
  selectTechnologies,
  selectTerritories,
  selectOrder,
  selectPage,
  selectShowActive,
  selectShowAllOffers,
  selectToken
} from '../selectors'

function* fetchJobsSaga() {
  const range = yield select(selectDateRange)
  const companies = yield select(selectCompanies)
  const technologies = yield select(selectTechnologies)
  const territories = yield select(selectTerritories)
  const orderBy = yield select(selectOrder)
  const page = yield select(selectPage)
  const showActive = yield select(selectShowActive)
  const showAllOffers = yield select(selectShowAllOffers)
  const token = yield select(selectToken)

  try {
    const result = yield call(
      getJobs,
      range,
      companies,
      technologies,
      territories,
      null,
      orderBy,
      page,
      showActive,
      showAllOffers,
      token
    )
    yield put(fetchJobsActionSuccess(result))
  } catch (e) {
    yield put(fetchJobsActionError(e))
  }
}

export function* watchFetchJobsSaga() {
  yield takeLatest(FETCH_JOBS, fetchJobsSaga)
}
