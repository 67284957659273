import Home from './pages/Home/Home'
import JobList from './pages/JobList/JobList'
import Login from './pages/Auth/login/Login'
import RemindPassword from './pages/Auth/remind-password/RemindPassword'
import NotFound from './components/NotFound/NotFound'
import HomeDemo from './pages/Home/HomeDemo'

import config from './app.config'

const basicMeta = {
  title: `${config.name}`,
  description: ''
}

const Routes = [
  {
    path: '/',
    exact: true,
    private: true,
    component: Home
  },
  {
    path: '/login',
    component: Login,
    private: false,
    metadata: {
      title: `${config.name} - Login`,
      description: 'Access to the private area'
    }
  },
  {
    path: '/recordar-contrasena',
    component: RemindPassword,
    private: false,
    metadata: {
      title: `${config.name} - Recordar contraseña`,
      description: 'Recordar contraseña'
    }
  },
  {
    path: '/listado',
    component: JobList,
    private: true,
    metadata: {
      title: `${config.name} - Listado`,
      description: 'Listado de puestos'
    }
  },
  {
    path: '/demo',
    component: HomeDemo,
    private: true
  },
  {
    component: NotFound,
    private: true
  }
].map(r => {
  return Object.assign(
    {},
    {
      metadata: basicMeta
    },
    r
  )
})

export default Routes
