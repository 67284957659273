import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CategoryCheckItem from './CategoryCheckItem/CategoryCheckItem.component'
import './CategoryCheck.scss'

const CategoryCheck = ({
  className,
  categoryTitle,
  dataItems,
  selectedItems,
  onChangeCategory,
  showCoincidencesNumber,
  resetFilter,
  placeHolder,
  showSearch
}) => {

  const [searchString, setSearchString] = useState('');
  const [searchResult, setSearchResult] = useState('');

  const onResetFilterClick = categoryTitle => {
    resetFilter(categoryTitle);
  }

  const onChangeSearch = (e) => {
    setSearchString(e.target.value.trim().toLowerCase());
  }

  useEffect(() => {
    let matches = dataItems.filter((t) => t.key.toLowerCase().match(searchString));
    setSearchResult(matches);
  }, [searchString, dataItems]);

  return (
    <nav className={`category-check ${className}`}>
      <div className="category-check-content">
        <strong className="category-title">{categoryTitle}</strong>

        <div className="selectedItems">
          {selectedItems && Object.keys(selectedItems).map((item, index) => (
            selectedItems[item] ?
              <div className="item" key={index}>
                {item}
              </div>
              : ''
          ))}
        </div>

        {showSearch &&
          <div className="search">
            <label className="search-label" htmlFor="Search">Búsqueda</label>
            <input className="search-form" placeholder={placeHolder} type="text" onChange={onChangeSearch}></input>
          </div>
        }

        {searchResult.length === 0 && (
          <div className="empty">No hay resultados</div>
        )}

        {searchResult.length ?
          searchResult.map(({ label, key, value }) => (
            <CategoryCheckItem
              key={key}
              categoryCheckTitle={label}
              categoryValue={key}
              countResults={showCoincidencesNumber ? value : false}
              checked={!!selectedItems[key]}
              onChangeCategory={onChangeCategory}
            />
          ))
          : ''}
      </div>
      <div className="category-footer">
        <button onClick={() => onResetFilterClick(categoryTitle)}>
          Resetear filtros
        </button>
      </div>
    </nav >
  )
}

export default CategoryCheck

CategoryCheck.propTypes = {
  className: PropTypes.string,
  categoryTitle: PropTypes.string,
  children: PropTypes.node
}
