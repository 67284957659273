import { useEffect, useState } from 'react'
import { setFiltersAction } from '../redux'

const getStateFromUrlParam = param => {
  return param.split(',').reduce((prev, curr) => {
    return { ...prev, [curr]: true }
  }, {})
}

export const useSetFiltersOnLoad = (location, dispatch) => {
  const [previousState, setPreviousState] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    const filters = {}
    if (params.has('companies')) {
      filters.companies = getStateFromUrlParam(params.get('companies'))
    }
    if (params.has('technologies')) {
      filters.technologies = getStateFromUrlParam(params.get('technologies'))
    }
    if (params.has('territories')) {
      filters.territories = getStateFromUrlParam(params.get('territories'))
    }
    if (params.has('range')) {
      filters.range = params.get('range')
    }
    if (params.has('order')) {
      filters.order = params.get('order')
    }
    if (params.has('page')) {
      filters.page = params.get('page')
    }
    if (params.has('detail')) {
      filters.detail = params.get('detail')
    }
    if (params.has('showActive')) {
      filters.showActive = params.get('showActive') === 'true'
    }

    if (params.has('showAllOffers')) {
      filters.showAllOffers = params.get('showAllOffers') === 'true'
    }

    if (params.has('listViewMode')) {
      filters.listViewMode = params.get('listViewMode')
    }

    if (!previousState) {
      dispatch(setFiltersAction(filters))
      setPreviousState(JSON.stringify(filters))
    }
  }, [location.search, dispatch, previousState])
}
