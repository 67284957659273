import React from 'react'
import config from '../../../app.config'
import { Helmet } from 'react-helmet'
import './remind-password.scss'
import { Link } from 'react-router-dom'

import logo from '../../../images/logo.jpg'

function Login(props) {
  // const [user, setUser] = useState('')
  // const dispatch = useDispatch()

  // const sendRemindPassword = e => {
  //   e.preventDefault()
  //   e.stopPropagation()

  //   dispatch(remindPasswordAction({
  //     user,
  //   }))
  // }

  return (
    <div className="login-page">
      <Helmet>
        <title>{config.name} - Recordar contraseña</title>
        <meta name="description" content="Acceso usuarios área privada" />
      </Helmet>
      <div className="remind">
        <img src={logo} className="App-logo" alt="logo" />
        <h6>He olvidado mi contraseña</h6>

        <p>
          Si has olvidado tu contraseña, por favor, escribe un e-mail a{' '}
          <a href="mailto:soporte@digitalmarketjobs.com">
            soporte@digitalmarketjobs.com
          </a>{' '}
          y te enviaremos una contraseña temporal.
        </p>
        <div className="go-to-login">
          <Link to="/login">Volver al inicio de sesión</Link>
        </div>
      </div>
    </div>
  )
}

export default Login
