import React from 'react'
import PropTypes from 'prop-types'
import './DataBarsItem.scss'

const DataBarsItem = ({ titleData, currentData, totalData, className }) => {
  const percentage = `${(currentData / totalData) * 100}%`
  return (
    <div className={`data-bars-item ${className}`}>
      <strong>{titleData}</strong>
      <div className="bar-out">
        <div className="bar-in" style={{ width: percentage }}></div>
      </div>
      <footer>
        <small>{currentData}</small>
        <small>{totalData}</small>
      </footer>
    </div>
  )
}

export default DataBarsItem

DataBarsItem.propTypes = {
  className: PropTypes.string,
  titleData: PropTypes.string,
  totalData: PropTypes.number,
  currentData: PropTypes.number
}
