import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import DataBarsItem from './DataBarsItem/DataBarsItem.component'
import TextButton from '../Layout/TextButton/TextButton.component'

import { pickTop } from '../../util'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import { selectShowAllOffers } from '../../redux'
import { TEXTS } from './constants'
import './DataBarsContainer.scss'

const DataBarsContainer = ({
  jobsSummary,
  filtersState,
  loading,
  location
}) => {
  const showAllOffers = useSelector(selectShowAllOffers);
  const companies = jobsSummary.companiesFiltered
    ? pickTop(3, jobsSummary.companiesFiltered)
    : []
  const technologies = jobsSummary.technologiesFiltered
    ? pickTop(3, jobsSummary.technologiesFiltered)
    : []
  const titles = jobsSummary.titleFiltered ? pickTop(3, jobsSummary.titleFiltered) : []
  const totalFiltered = parseInt(jobsSummary.totalFiltered, 10)

  let message
  if (loading) {
    message = TEXTS.LOADING
  }
  if (totalFiltered === 0 || !totalFiltered) {
    message = TEXTS.NO_RESULTS
  }
  const { isDark } = useTheme()

  return (
    <section className={`data-bars-container ${isDark ? '--dark' : ''}`}>
      {message ? (
        <div className="demand-list">
          <strong className="title">{message}</strong>
        </div>
      ) : (
          <>
            <div className="demand-list">
              <strong className="title">{TEXTS.JOBS}</strong>
              {titles.length === 0 && (
                <div className="empty">{TEXTS.UNABLE_LOAD}</div>
              )}
              {titles.map(([name, value]) => {
                return (
                  <DataBarsItem
                    key={name}
                    titleData={name}
                    currentData={value}
                    totalData={totalFiltered}
                  />
                )
              })}
            </div>
            <div className="demand-list">
              <strong className="title">{showAllOffers ? TEXTS.CATEGORIES : TEXTS.TECHNOLOGIES}</strong>
              {technologies.length === 0 && (
                <div className="empty">{TEXTS.UNABLE_LOAD}</div>
              )}
              {technologies.map(([name, value]) => {
                return (
                  <DataBarsItem
                    key={name}
                    titleData={name}
                    currentData={value}
                    totalData={totalFiltered}
                  />
                )
              })}
            </div>

            <div className="demand-list">
              <strong className="title">{TEXTS.COMPANIES}</strong>
              {companies.length === 0 && (
                <div className="empty">{TEXTS.UNABLE_LOAD}</div>
              )}
              {companies.map(([name, value]) => {
                return (
                  <DataBarsItem
                    key={name}
                    titleData={name}
                    currentData={value}
                    totalData={totalFiltered}
                  />
                )
              })}
            </div>
            <footer>
              <Link to={`/listado${location.search}`} title="Listado">
                <TextButton titleBtn="Ver listado" />
              </Link>
            </footer>
          </>
        )}
    </section>
  )
}

export default withRouter(DataBarsContainer)

DataBarsContainer.propTypes = {
  className: PropTypes.string,
  jobsSummary: PropTypes.object
}
