// export const config = {
//   authApi: 'https://api-dev.digitalmarketjob.com/auth',
//   jobsApi: 'https://api-dev.digitalmarketjob.com/jobs'
// }
// export const config = {
//   authApi : 'http://localhost:3002',
//   jobsApi: 'http://localhost:3001'
// }

export const config = {
  authApi : 'https://digital-market-jobs-auth.azurewebsites.net',
  jobsApi: 'https://digital-market-jobs-api.azurewebsites.net'
}
