import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal/Modal'
import TextButton from '../Layout/TextButton/TextButton.component'

import './change-password-modal.scss'
import { logoutAction } from '../../redux/actions'
import { selectUser } from '../../redux/selectors'
import { changePassword } from '../../services/auth/authService'

const FIELDS = {
  oldPassword: 'oldPassword',
  newPassword: 'newPassword',
  newPasswordConfirm: 'newPasswordConfirm'
}
const ERROR_DONT_MATCH = 'Las contraseñas no coinciden'

export default function ChangePasswordModal(props) {
  const dispatch = useDispatch()
  const { opened, onClickClose, isDark } = props
  const user = useSelector(selectUser)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const onChangeInput = (field, value) => {
    switch (field) {
      case FIELDS.oldPassword: {
        setOldPassword(value)
        break
      }
      case FIELDS.newPassword: {
        if (value !== '' && value !== newPasswordConfirm) {
          setErrorMessage(ERROR_DONT_MATCH)
        } else {
          setErrorMessage(null)
        }
        setNewPassword(value)
        break
      }
      case FIELDS.newPasswordConfirm: {
        if (value !== '' && value !== newPassword) {
          setErrorMessage(ERROR_DONT_MATCH)
        } else {
          setErrorMessage(null)
        }
        setNewPasswordConfirm(value)
        break
      }
      default:
        break
    }
  }

  const onClickChangePassword = () => {
    changePassword({
      user: user.name,
      oldPassword,
      newPassword
    })
      .then(result => {
        onClickClose()
        dispatch(logoutAction())
      })
      .catch(err => {
        const message = err.response.data ? err.response.data.message : null
        if (message === 'Missing credentials') {
          setErrorMessage('La contraseña no es corrrecta')
        } else {
          setErrorMessage('Se ha producido un error')
        }
      })
  }

  return (
    <Modal {...{ opened, onClickClose, isDark }}>
      <section className="change-password">
        <h2> Cambio de contraseña</h2>
        <h4>
          Atención: será necesario iniciar la sesión de nuevo después de cambiar
          la contraseña
        </h4>
        <label for={FIELDS.oldPassword}>Contraseña actual</label>
        <input
          id={FIELDS.oldPassword}
          type="password"
          plaaceholder
          onChange={e => onChangeInput(FIELDS.oldPassword, e.target.value)}
        ></input>
        <label for={FIELDS.newPassword}>Nueva contraseña</label>
        <input
          id={FIELDS.newPassword}
          type="password"
          plaaceholder
          onChange={e => onChangeInput(FIELDS.newPassword, e.target.value)}
        ></input>
        <label for={FIELDS.newPasswordConfirm}>Repetir nueva contraseña</label>
        <input
          id={FIELDS.newPasswordConfirm}
          type="password"
          plaaceholder
          onChange={e =>
            onChangeInput(FIELDS.newPasswordConfirm, e.target.value)
          }
        ></input>
        {errorMessage ? <div className="error">{errorMessage}</div> : null}
        <TextButton
          titleBtn="Confirmar cambio"
          onClick={onClickChangePassword}
          disabled={!!errorMessage}
        />
      </section>
    </Modal>
  )
}
