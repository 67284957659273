export const LOG_OUT = 'USER/LOGOUT'
export const LOG_IN = 'USER/LOG_IN'
export const LOG_IN_SUCCESS = 'USER/LOG_IN_SUCCESS'
export const LOG_IN_ERROR = 'USER/LOG_IN_ERROR'
export const REMIND_PASSWORD = 'USER/REMIND_PASSWORD'
export const REMIND_PASSWORD_SUCCESS = 'USER/REMIND_PASSWORD_SUCCESS'
export const REMIND_PASSWORD_ERROR = 'USER/REMIND_PASSWORD_ERROR'

export function logoutAction(payload) {
  return {
    type: LOG_OUT,
    payload
  }
}

export function loginAction(payload) {
  return {
    type: LOG_IN,
    payload
  }
}

export function loginActionError(payload) {
  return {
    type: LOG_IN_ERROR,
    payload
  }
}

export function loginActionSuccess(payload) {
  return {
    type: LOG_IN_SUCCESS,
    payload
  }
}

export function remindPasswordAction(payload) {
  return {
    type: REMIND_PASSWORD,
    payload
  }
}

export function remindPasswordActionSuccess(payload) {
  return {
    type: REMIND_PASSWORD_SUCCESS,
    payload
  }
}

export function remindPasswordActionError(payload) {
  return {
    type: REMIND_PASSWORD_ERROR,
    payload
  }
}
