import React from 'react'
import PropTypes from 'prop-types'

const Footer = ({ className }) => <footer></footer>

export default Footer

Footer.propTypes = {
  className: PropTypes.string
}
