export const SET_TECHNOLOGY = 'FILTERS/SET_TECHNOLOGY'
export const SET_FILTERS = 'FILTERS/SET_FILTERS'
export const CLEAR_TECHNOLOGY = 'FILTERS/CLEAR_TECHNOLOGY'
export const SET_TERRITORY = 'FILTERS/SET_TERRITORY'
export const CLEAR_TERRITORY = 'FILTERS/CLEAR_TERRITORY'
export const SET_DATE_RANGE = 'FILTERS/SET_DATE_RANGE'
export const SET_COMPANY = 'FILTERS/SET_COMPANY'
export const CLEAR_COMPANY = 'FILTERS/CLEAR_COMPANY'
export const CLEAR_FILTERS = 'FILTERS/CLEAR_FILTERS'
export const SET_ORDER = 'FILTERS/SET_ORDER'
export const SET_PAGE = 'FILTERS/SET_PAGE'
export const SET_JOB_DETAIL = 'FILTERS/SET_JOB_DETAIL'
export const RESET_COMPANIES = 'FILTERS/RESET_COMPANIES'
export const RESET_TECHNOLOGIES = 'FILTERS/RESET_TECHNOLOGIES'
export const RESET_TERRITORIES = 'FILTERS/RESET_TERRITORIES'
export const SET_SHOW_ACTIVE = 'FILTERS/SET_SHOW_ACTIVE'
export const SET_SHOW_ALL_OFFERS = 'FILTERS/SET_SHOW_ALL_OFFERS'
export const SET_LIST_MODE = 'FILTERS/SET_LIST_MODE'

export function setFiltersAction(payload) {
  return {
    type: SET_FILTERS,
    payload
  }
}

export function setTechnologyAction(payload) {
  return {
    type: SET_TECHNOLOGY,
    payload
  }
}

export function setTerritoryAction(payload) {
  return {
    type: SET_TERRITORY,
    payload
  }
}

export function setDateRangeAction(payload) {
  return {
    type: SET_DATE_RANGE,
    payload
  }
}

export function setCompanyAction(payload) {
  return {
    type: SET_COMPANY,
    payload
  }
}

export function clearFilters(payload) {
  return {
    type: CLEAR_FILTERS,
    payload
  }
}

export function setOrder(payload) {
  return {
    type: SET_ORDER,
    payload
  }
}

export function setPage(payload) {
  return {
    type: SET_PAGE,
    payload
  }
}

export function setJobDetailAction(payload) {
  return {
    type: SET_JOB_DETAIL,
    payload
  }
}

export function resetTerritoriesAction() {
  return {
    type: RESET_TERRITORIES
  }
}

export function resetCompaniesAction() {
  return {
    type: RESET_COMPANIES
  }
}

export function resetTechnologiesAction() {
  return {
    type: RESET_TECHNOLOGIES
  }
}

export function setShowActiveOffersAction(payload) {
  return {
    type: SET_SHOW_ACTIVE,
    payload
  }
}

export function setShowAllOffersAction(payload) {
  return {
    type: SET_SHOW_ALL_OFFERS,
    payload
  }
}

export function setListModeAction(payload) {
  return {
    type: SET_LIST_MODE,
    payload
  }
}
