import React from 'react'
import PropTypes from 'prop-types'
import './MapSelectorFooter.scss'

const MapSelectorFooter = ({ zoom, pan }) => {
  return (
    <footer className="map-selector-footer">
      <nav className="map-selector-nav">
        <div className="map-selector-directions">
          <div className="map-selector-nav-row">
            <button onClick={() => pan(0, 30)}>↑</button>
          </div>
          <div className="map-selector-nav-row middle-row">
            <button onClick={() => pan(30, 0)}>←</button>
            <button onClick={() => pan(-30, 0)}>→</button>
          </div>
          <div className="map-selector-nav-row">
            <button onClick={() => pan(0, -30)}>↓</button>
          </div>
        </div>

        <div className="map-selector-nav-zoom">
          <div className="map-selector-nav-row">
            <button onClick={() => zoom(1.25)}>+</button>
            <button onClick={() => zoom(0.8)}>-</button>
          </div>
        </div>
      </nav>
    </footer>
  )
}

export default MapSelectorFooter

MapSelectorFooter.propTypes = {
  className: PropTypes.string
}
