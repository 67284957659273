import { put, call, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { LOG_IN, loginActionSuccess, loginActionError } from '../actions'
import { logIn } from '../../services/auth/authService'

function* loginSaga({ payload }) {
  const { user, password } = payload

  try {
    const { data } = yield call(logIn, {
      email: user,
      password
    })
    yield put(
      loginActionSuccess({
        user: { name: user },
        token: data.token
      })
    )

    yield put(push('/'))
  } catch (e) {
    yield put(loginActionError())
  }
}

export function* watchLoginSaga() {
  yield takeLatest(LOG_IN, loginSaga)
}
