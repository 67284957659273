import { call, put, takeLatest, select } from 'redux-saga/effects'
import { getTerritories } from '../../services/territories/territoriesService'

import {
  FETCH_TERRITORIES,
  fetchTerritoriesActionError,
  fetchTerritoriesActionSuccess
} from '../actions'

import { selectToken } from '../selectors'

function* fetchTerritoriesSaga() {
  try {
    const token = yield select(selectToken)

    const result = yield call(getTerritories, token)

    yield put(fetchTerritoriesActionSuccess(result))
  } catch (e) {
    console.error(e)
    yield put(fetchTerritoriesActionError(e))
  }
}

export function* watchFetchTerritoriesSaga() {
  yield takeLatest(FETCH_TERRITORIES, fetchTerritoriesSaga)
}
