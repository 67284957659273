export const FETCH_JOBS_SUMMARY = 'JOBS/FETCH_JOBS_SUMMARY'
export const FETCH_JOBS_SUMMARY_SUCCESS = 'JOBS/FETCH_JOBS_SUMMARY_SUCCESS'
export const FETCH_JOBS_SUMMARY_ERROR = 'JOBS/FETCH_JOBS_SUMMARY_ERROR'

export function fetchJobsSummaryAction(payload) {
  return {
    type: FETCH_JOBS_SUMMARY,
    payload
  }
}

export function fetchJobsSummaryActionError(payload) {
  return {
    type: FETCH_JOBS_SUMMARY_ERROR,
    payload
  }
}

export function fetchJobsSummaryActionSuccess(payload) {
  return {
    type: FETCH_JOBS_SUMMARY_SUCCESS,
    payload
  }
}
