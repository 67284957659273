import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import config from '../../app.config'
import { Helmet } from 'react-helmet'

import { useFetchJobs } from '../../effects/useFetchJobs'
import {
  selectJobsSummaryState,
  setOrder,
  selectViewType,
  setListModeAction
} from '../../redux'
import CategoriesContainerContainer from '../../components/CategoriesContainer/CategoriesContainer.component'
import CardsContainer from '../../components/CardsContainer/CardsContainer'
import { ReactComponent as GridSvg } from '../../grid.svg'
import { ReactComponent as ListSvg } from '../../list.svg'
import Pagination from './Pagination'
import './job-list.scss'
import { VIEW_TYPE } from '../../redux/reducers/filters.reducer'
import { useTheme } from '../../components/ThemeProvider/ThemeProvider'

const SORT_BY = {
  COMPANY: 'company',
  TECHNOLOGIES: 'technologies',
  TERRITORIES: 'territories',
  TITLE: 'title',
  PUBLISHED: 'published'
}

export default function JobList() {
  const viewType = useSelector(selectViewType)
  const jobsSummary = useSelector(selectJobsSummaryState)
  const dispatch = useDispatch()

  useFetchJobs()

  const onClickSortBy = orderBy => {
    dispatch(setOrder(orderBy))
  }

  const setViewType = viewType => {
    dispatch(setListModeAction(viewType))
  }

  const { isDark } = useTheme()

  return (
    <div className="listado">
      <Helmet>
        <title>{config.name} - Listado</title>
        <meta name="description" content="Listado" />
      </Helmet>
      <CategoriesContainerContainer />
      <div className="area-center ">
        <header>
          <strong className="title">
            Ofertas publicadas:
            <span>
              {jobsSummary.loading
                ? 'Cargando...'
                : jobsSummary.data.totalFiltered}
            </span>
          </strong>

          <div className="top-manager">
            <strong className="title small">
              Ordenar por:
              <span>
                Ubicación<img src="/assets/icons/arrow.svg" alt="flecha"></img>
                <ul>
                  <li onClick={() => onClickSortBy(SORT_BY.PUBLISHED)}>
                    Fecha de publicación
                  </li>

                  <li onClick={() => onClickSortBy(SORT_BY.COMPANY)}>
                    Empresas
                  </li>
                  <li onClick={() => onClickSortBy(SORT_BY.TERRITORIES)}>
                    Ubicación
                  </li>
                  <li onClick={() => onClickSortBy(SORT_BY.TITLE)}>Puesto</li>
                </ul>
              </span>
            </strong>

            <div className={`icons-view-type ${isDark ? '--dark' : ''}`}>
              <div className="icon" onClick={() => setViewType(VIEW_TYPE.GRID)}>
                <GridSvg
                  color={viewType === VIEW_TYPE.GRID ? '#044DEB' : '#B0B7C5'}
                />
              </div>
              <div className="icon" onClick={() => setViewType(VIEW_TYPE.LIST)}>
                <ListSvg
                  color={viewType === VIEW_TYPE.LIST ? '#044DEB' : '#B0B7C5'}
                />
              </div>
            </div>
          </div>
        </header>
        {!jobsSummary.error && (
          <Pagination totalFiltered={jobsSummary.data.totalFiltered} />
        )}
        <CardsContainer viewType={viewType} />
      </div>
    </div>
  )
}
