import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Link, withRouter, matchPath } from 'react-router-dom'

import logo from '../../images/logo.jpg'
import logoEU from '../../images/logo-eu.png'
import logoWhite from '../../images/logo-inserta-white.svg'
import logoEUWhite from '../../images/logo-eu-white.png'
import Container from '../Layout/Container/Container.component'
import IconButton from '../Layout/IconButton/IconButton.component'
import TextButton from '../Layout/TextButton/TextButton.component'
import { selectIsLoggedIn, logoutAction } from '../../redux'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal'
import './Header.scss'

function Header({ className, ...props }) {
  const dispatch = useDispatch()

  const isLoggedIn = useSelector(selectIsLoggedIn)
  const { isDark, toggleFontSize, toggleTheme } = useTheme()

  const [showModal, setShowModal] = useState(false)

  const onClickLogout = () => {
    dispatch(logoutAction())
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const onChangeFontClick = () => {
    toggleFontSize()
  }
  const onChangeThemeClick = () => {
    toggleTheme()
  }
  const onChangePasswordClick = () => {
    setShowModal(true)
  }

  return (
    <>
      <header className={`main-header ${isDark ? '--dark' : ''}`}>
        <Container className="flex v-center between">
          <div className="navigation">
            <div className="logo">
              <Link to="/" title="Ir al inicio">
                <img
                  src={isDark ? logoWhite : logo}
                  className="App-logo"
                  alt="Logo Inserta"
                />
                <img
                  src={isDark ? logoEUWhite : logoEU}
                  className="logo-eu"
                  alt="Logo Unión Europea"
                />
              </Link>
            </div>
            {isLoggedIn ? (
              <ul>
                <li
                  className={
                    props.location.pathname === '/' ? 'links active' : 'links'
                  }
                >
                  <Link to={`/${props.location.search}`} title="Mapa">
                    Mapa
                  </Link>
                </li>
                <li
                  className={
                    !!matchPath(props.location.pathname, '/listado')
                      ? 'active links'
                      : 'links'
                  }
                >
                  <Link to={`/listado${props.location.search}`} title="Listado">
                    Listado
                  </Link>
                </li>
              </ul>
            ) : null}
          </div>

          <div className="sd-actions">
            <IconButton onClick={onChangeFontClick}>A+</IconButton>
            <IconButton btnType="NightMode" onClick={onChangeThemeClick}>
              Night Mode
            </IconButton>
            {isLoggedIn ? (
              <>
                <TextButton titleBtn="Cerrar sesión" onClick={onClickLogout} />
                <TextButton
                  titleBtn="Cambiar contraseña"
                  onClick={onChangePasswordClick}
                />
              </>
            ) : null}
          </div>
        </Container>
      </header>
      <ChangePasswordModal
        {...{ opened: showModal, onClickClose: closeModal }}
      />
    </>
  )
}

export default withRouter(Header)

Header.propTypes = {
  className: PropTypes.string
}
