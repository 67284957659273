import { createSelector } from 'reselect'

export const selectUserState = state => state.userState

export const selectUser = createSelector(
  selectUserState,
  userState => userState.user
)
export const selectToken = createSelector(
  selectUserState,
  userState => userState.token
)

export const selectIsLoggedIn = createSelector(selectToken, token => !!token)
