import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import logo from '../../images/logo.jpg'
import logoEU from '../../images/logo-eu.png'
import logoWhite from '../../images/logo-inserta-white.svg'
import logoEUWhite from '../../images/logo-eu-white.png'
import { ReactComponent as ShareSvg } from '../../images/share.svg'
import { ReactComponent as ExportSvg } from '../../images/export.svg'
import Container from '../Layout/Container/Container.component'
import {
  selectDateRange,
  selectFiltersState,
  setDateRangeAction,
  selectToken
} from '../../redux'
import { useTheme } from '../ThemeProvider/ThemeProvider'
import { copyTextToClipboard } from '../../util'
import { getJobsCsv } from '../../services/jobs/jobsService'
import './SubHeader.scss'

function SubHeader({ history }) {
  const [showLogo, setShowLogo] = useState(false)
  const [isSharedMessageVisible, setSharedMessageVisibility] = useState(false)
  const { isDark } = useTheme()
  const dispatch = useDispatch()

  const selectedRange = useSelector(selectDateRange)
  const filtersState = useSelector(selectFiltersState)
  const token = useSelector(selectToken)

  const onChangeRange = range => {
    dispatch(setDateRangeAction(range))
  }

  const onExportSearchClick = () => {
    const {
      range,
      companies,
      technologies,
      territories,
      order,
      page,
      showActive,
      showAllOffers
    } = filtersState
    getJobsCsv(
      range,
      companies,
      technologies,
      territories,
      null,
      order,
      page,
      showActive,
      showAllOffers,
      token
    )
  }

  const onShareViewClick = () => {
    copyTextToClipboard(window.location.href)
    setSharedMessageVisibility(true)
    setTimeout(() => {
      setSharedMessageVisibility(false)
    }, 3000)
  }

  const showLogos = () => {
    if (window.scrollY <= 75) {
      setShowLogo(false)
    } else if (window.scrollY > 75) {
      setShowLogo(true)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', showLogos)
  }, [])

  return (
    <nav className={isDark ? 'sub-header --dark' : 'sub-header'}>
      <Container className="flex v-center between">
        <div className={showLogo ? 'hidden showLogos' : 'hidden'}>
          <div className="logo">
            <img
              src={isDark ? logoWhite : logo}
              className="App-logo"
              alt="Logo Inserta"
            />
            <img
              src={isDark ? logoEUWhite : logoEU}
              className="logo-eu"
              alt="Logo Unión Europea"
            />
          </div>
        </div>
        {window.addEventListener('scroll', showLogos)}
        <div className="navigation">
          <ul>
            <li
              className={`${selectedRange === 'today'
                ? 'subheader-date-range-item active'
                : 'subheader-date-range-item'
                }`}
              onClick={() => onChangeRange('today')}
            >
              <div className="date-range">Hoy</div>
            </li>
            <li
              className={`${selectedRange === 'last-week'
                ? 'subheader-date-range-item active'
                : 'subheader-date-range-item'
                }`}
              onClick={() => onChangeRange('last-week')}
            >
              <div className="date-range">
                <span>Última</span> semana
              </div>
            </li>
            <li
              className={`${selectedRange === 'last-month'
                ? 'subheader-date-range-item active'
                : 'subheader-date-range-item'
                }`}
              onClick={() => onChangeRange('last-month')}
            >
              <div className="date-range">
                <span>Último</span> mes
              </div>
            </li>
            <li
              className={`${selectedRange === 'last-year'
                ? 'subheader-date-range-item active'
                : 'subheader-date-range-item'
                }`}
              onClick={() => onChangeRange('last-year')}
            >
              <div className="date-range">
                <span>Último</span> año
              </div>
            </li>
          </ul>
        </div>

        <div className="subheader-actions">
          <button onClick={onShareViewClick}>
            <ShareSvg color={isDark ? '#FFF' : '#044deb'} />
          </button>
          <button onClick={onExportSearchClick}>
            <ExportSvg color={isDark ? '#FFF' : '#044deb'} />
          </button>
        </div>
      </Container>
      <div
        className={
          isSharedMessageVisible
            ? 'subheader-message active'
            : 'subheader-message'
        }
      >
        El enlace ha sido copiado al portapapeles
      </div>
    </nav>
  )
}

export default SubHeader

SubHeader.propTypes = {
  className: PropTypes.string
}
