import React from 'react'
import PropTypes from 'prop-types'
import './TextButton.scss'

const TextButton = ({ className, titleBtn, onClick, disabled = false }) => (
  <button
    className={disabled ? 'text-button disabled' : 'text-button'}
    onClick={onClick}
  >
    {titleBtn}
  </button>
)

export default TextButton

TextButton.propTypes = {
  className: PropTypes.string,
  titleBtn: PropTypes.string
}
