import React, { useState } from 'react'

import TextButton from '../Layout/TextButton/TextButton.component'

import { useTheme } from '../ThemeProvider/ThemeProvider'
import CompanyLogo from '../CompanyLogo/CompanyLogo'
import { formatDate, copyTextToClipboard } from '../../util'
import './job-modal.scss'

export default function JobModal(props) {
  const { isDark } = useTheme()
  const [isSharedMessageVisible, setSharedMessageVisibility] = useState(false)

  const { opened, onClickClose, job } = props

  if (!job) {
    return null
  }

  const {
    company,
    company_logo,
    title,
    published,
    description,
    technologies,
    active,
    technological
  } = job

  const formattedDate = formatDate(published)

  const isEmpleateGobLogo = () => {
    if (company_logo) {
      return company_logo.split('/').pop() === 'WEB.png';
    }
    return false;
  }

  const onClickShare = () => {
    copyTextToClipboard(window.location.href)
    setSharedMessageVisibility(true)
    setTimeout(() => {
      setSharedMessageVisibility(false)
    }, 3000)
  }

  return (
    <div
      className={`job-modal ${opened ? 'opened' : ''}  ${isDark ? '--dark' : ''}`}>
      <div className="overlay" onClick={onClickClose}></div>
      <div className="actions">
        <div className="close" onClick={onClickClose}></div>
      </div>
      <div className="content-modal">
        <div className="content-modal-inner">
          <div className="description">
            {!active && <div className="closed">Cerrada</div>}
            <CompanyLogo company={company} logo={company_logo} whiteBackground={isEmpleateGobLogo()} />
            <h3>{title}</h3>
            <p>{description}</p>
          </div>

          <div className="requirements">
            <footer>
              <span>{company}</span>
              <span>{formattedDate}</span>
            </footer>

            <h3>{technological ? 'Tecnologías' : 'Etiquetas'}</h3>
            <ul>
              {technologies.map((tech, index) => (
                <li key={index}>{tech}</li>
              ))}
            </ul>

            <TextButton onClick={onClickShare} titleBtn="Compartir oferta" />
            <div
              className={
                isSharedMessageVisible
                  ? 'share-message visible'
                  : 'share-message'
              }
            >
              El enlace ha sido copiado al portapapeles
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
