import { createSelector } from 'reselect'

export const selectTerritoriesState = state => state.territoriesState

export const selectAllTerritories = createSelector(
  selectTerritoriesState,
  territoriesState => territoriesState.territories || []
)

export const selectAllTerritoriesOrderedAsList = createSelector(
  selectAllTerritories,

  territories => {
    return territories.map(t => ({
      label: t.name,
      key: t.code,
      value: t.code
    }))
  }
)
