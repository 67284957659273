import { createSelector } from 'reselect'

export const selectJobsState = state => state.jobsState

export const selectJobs = createSelector(
  selectJobsState,
  jobsState => jobsState.jobs
)

export const selectLastId = createSelector(selectJobsState, jobsState => {
  if (!jobsState.jobs.length) {
    return null
  }
  return jobsState.jobs[jobsState.jobs.length - 1]['_id']
})
